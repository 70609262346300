import { Carousel, Image } from "@global";
import {
  Maybe,
  SanityBlogOrCategoryOrCollectionOrRecipe,
} from "@graphql-types";
import { Container, H3, P } from "@util/standard";
import { isSanityBlog, isSanityCollection, isSanityRecipe } from "@util/types";
import styled from "styled-components";

import React from "react";
import { navigate } from "gatsby";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  data?: Maybe<Maybe<SanityBlogOrCategoryOrCollectionOrRecipe>[]> | undefined;
}

const GradientContainer = styled(Container)`
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.55));
`;

const CarouselWrapper = styled.div<{
  hasDarkBackground?: boolean;
  width?: string;
  margin?: string;
  alignDots?: string;
  dotMargins?: string;
  dotsToLeft?: boolean;
  slickNextRight?: number;
  slickNextLeft?: number;
}>`
  width: 83%;
  margin: auto;

  ${({ width }) => width && `width: ${width};`};
  ${({ margin }) => margin && `margin: ${margin};`};

  .slick-dots {
    position: initial;
    margin: 50px auto 0px auto;
    ${({ alignDots }) => alignDots && `text-align: ${alignDots};`};
    ${({ dotMargins }) => dotMargins && `margin: ${dotMargins};`};
    li {
      width: 8px;
      button::before {
        ${({ alignDots }) => alignDots && `text-align: ${alignDots};`};
      }
    }
  }

  .slick-slide {
    overflow: hidden;
  }

  .slick-dots li button:before {
    font-size: 10px;

    ${({ hasDarkBackground }) =>
      hasDarkBackground &&
      `color: white;
  `}
  }
`;

const WhatsNew = (props: Props) => {
  const { data } = props;
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  if (!data) return null;
  
  return (
    <Container
      width="auto"
      margin="30px 150px 50px 150px"
      tabletMargin="30px 20px"
      flexDirection="column"
    >
      <Container
        display="flex"
        flexDirection="row"
        alignItems="center"
        height="40px"
        margin="0px auto 30px 0px"
      >
        <H3 fontSize={30} fontWeight="400" margin="0 0 0 5px">
          Explore What's New
        </H3>
      </Container>

      <CarouselWrapper
        width="100%"
        alignDots="left"
        dotMargins="22px 0px 100px 0px"
      >
        <Carousel
          slidesToShow={isTabletWidth ? 2 : isMobileWidth ? 1 : 3}
          slidesToScroll={isTabletWidth ? 2 : isMobileWidth ? 1 : 3}
          infinite={false}
        >
          {data?.map((object, i) => {
            if (isSanityRecipe(object)) {
              return (
                <Container
                  margin="0px 6px"
                  overflow="hidden"
                  position="relative"
                  key={i}
                >
                  <Image
                    isBackground
                    imageData={
                      object.info?.featuredMedia?.image?.asset?.gatsbyImageData
                    }
                  >
                    <Container
                      width="100%"
                      height={
                        isTabletWidth
                          ? "400px"
                          : isMobileWidth
                          ? "300px"
                          : "500px"
                      }
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      overflow="hidden"
                      position="relative"
                    >
                      <GradientContainer
                        cursor="pointer"
                        onClick={() =>
                          navigate(`/recipe/${object.info.slug?.current}`)
                        }
                        flexDirection="column"
                        width="100%"
                      >
                        <P
                          margin="0px 20px"
                          textAlign="left"
                          color="white"
                          fontSize={15}
                        >
                          RECIPE
                        </P>
                        <P
                          margin="12px 20px"
                          textAlign="left"
                          bold
                          color="white"
                          fontSize={25}
                        >
                          {object.info?.title}
                        </P>
                        <P
                          margin="0px 20px 14px 20px"
                          textAlign="left"
                          color="white"
                          fontSize={17}
                        >
                          {object.info?.prepTime}mins Prep |{" "}
                          {object.info?.cookTime}
                          mins Cook
                        </P>
                      </GradientContainer>
                    </Container>
                  </Image>
                </Container>
              );
            }

            if (isSanityCollection(object)) {
              return (
                <Container margin="0px 6px" overflow="hidden" key={i}>
                  <Image
                    isBackground
                    imageData={
                      object.main?.hero?.videoUrl?.image?.asset?.gatsbyImageData
                    }
                  >
                    <Container
                      width="100%"
                      height={
                        isTabletWidth
                          ? "400px"
                          : isMobileWidth
                          ? "300px"
                          : "500px"
                      }
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      overflow="hidden"
                    >
                      <GradientContainer
                        cursor="pointer"
                        onClick={() =>
                          navigate(`/${object.main?.slug?.current}`)
                        }
                        flexDirection="column"
                        width="100%"
                      >
                        <P
                          margin="0px 20px"
                          textAlign="left"
                          color="white"
                          fontSize={15}
                        >
                          COLLECTION
                        </P>
                        <P
                          margin="12px 20px"
                          textAlign="left"
                          bold
                          color="white"
                          fontSize={25}
                        >
                          {object.main?.title}
                        </P>
                      </GradientContainer>
                    </Container>
                  </Image>
                </Container>
              );
            }

            if (isSanityBlog(object)) {
              return (
                <Container margin="0px 6px" overflow="hidden" key={i}>
                  <Image
                    isBackground
                    imageData={object.image?.asset?.gatsbyImageData}
                  >
                    <Container
                      width="100%"
                      height={
                        isTabletWidth
                          ? "400px"
                          : isMobileWidth
                          ? "300px"
                          : "500px"
                      }
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      overflow="hidden"
                    >
                      <GradientContainer
                        cursor="pointer"
                        onClick={() =>
                          navigate(`/blog/${object.slug?.current}`)
                        }
                        flexDirection="column"
                        width="100%"
                      >
                        <P
                          margin="0px 20px"
                          textAlign="left"
                          color="white"
                          fontSize={15}
                        >
                          BLOG | {object.category?.main?.title}
                        </P>
                        <P
                          margin="12px 20px"
                          textAlign="left"
                          bold
                          color="white"
                          fontSize={25}
                        >
                          {object.title}
                        </P>
                      </GradientContainer>
                    </Container>
                  </Image>
                </Container>
              );
            }
            return null;
          })}
        </Carousel>
      </CarouselWrapper>
    </Container>
  );

  return <></>;
};

export default WhatsNew;
